import React, { Component } from 'react'
import { Collapse } from 'react-collapse'
import PlusSVG from '../images/PlusSVG'
import MinusSVG from '../images/MinusSVG'
import './collapseComponent.css'

export default class CollapseComponent extends Component {

  state = {
    isOpened: false
  }

  render() {
    return (
      <div className='collapsible-component-container'>
        <div className='collapse-component-heading' onClick={() => this.setState({ isOpened: !this.state.isOpened })}>
          <div className='collapse-component-heading-item icon'>{this.state.isOpened ? <MinusSVG/> : <PlusSVG/>}</div>
          <div className='collapse-component-heading-item text'>{this.props.Heading}</div>
        </div>
        <Collapse isOpened={this.state.isOpened || false}>
          <div className='collapse-component-content'>
            {this.props.Content}
          </div>
        </Collapse>
      </div>
    )
  }
}

CollapseComponent.defaultProps = {
  Heading: 'Thing',
  Content: null,
}


