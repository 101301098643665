import React from 'react'

export const getBio = (ID) => {
  switch (parseInt(ID)) {
    case 1:
      return (
        <div>
          <h4>Biography:</h4>
          <p>Jacob is a co-founder of Szenberg &amp; Okun PLLC.  Jacob's practice focuses on general corporate practice, with an emphasis on real estate aquisition and disposition, mergers and acquisitions, financings, joint ventures and other corporate transactions. He represents emerging growth and mature companies.</p>
          <p>Prior to founding Szenberg &amp; Okun PLLC, Jacob was General Counsel at Fairchild Properties, Ltd., a real estate investment company, and an associate for six years in the New York offices of Sullivan &amp; Cromwell LLP.</p>
        </div>
      )
    case 2:
      return (
        <div>
          <h4>Biography:</h4>
          <p>Avi is a co-founder of Szenberg &amp; Okun PLLC. Avi handles domestic and international corporate matters of all types, with an emphasis on commercial real estate transactions, mergers and acquisitions, joint ventures, office and ground leases and myriad other corporate and contractual matters and transactions.</p>
          <p>Prior to co-founding Szenberg &amp; Okun PLLC, Avi served as an associate and special counsel in the New York offices of Sullivan &amp; Cromwell LLP for more than seventeen years.</p>
        </div>
      )
    case 3:
      return (
        <div>
          <h4>Biography:</h4>
          <p>Daniel is a Partner at Szenberg &amp; Okun PLLC.  Daniel's practice focuses on general corporate practice, with an emphasis on real estate acquisitions and dispositions, secured financings, preferred equity investments, joint ventures and other corporate transactions.</p>
          <p>Prior to joining Szenberg &amp; Okun PLLC, Daniel was a senior associate for seven years in the New York offices of Sullivan &amp; Cromwell LLP. </p>
        </div>
      )
    case 4:
      return (
        <div>
          <h4>Biography:</h4>
          <p>Daniel is a Partner at Szenberg &amp; Okun PLLC. Daniel's practice focuses on commercial real estate transactions, including acquisitions and dispositions, financings, joint ventures and fund formation, securities offerings and transactions, broker-dealer, investment adviser, securities and derivatives regulatory matters and other corporate transactions and matters.  </p>
          <p>Prior to joining Szenberg &amp; Okun PLLC, Daniel was a Director in the legal department of Bank of America Merrill Lynch.  Prior to Bank of America Merrill Lynch, Daniel was an in-house attorney at Barclays Bank PLC. Daniel started his career at Sullivan &amp; Cromwell LLP where he was an Associate in the New York office for four years.  During this time, Daniel was also a Secondee in the legal department at Goldman Sachs. </p>
        </div>
      )
    case 5:
      return (
        <div>
          <h4>Biography:</h4>
          <p>Ms. Heino is counsel at Szenberg &amp; Okun PLLC. She specializes in corporate matters of all types, with an emphasis on commercial real estate transactions. </p>
          <p>Prior to joining Szenberg &amp; Okun PLLC, Ms. Heino served as an associate in the New York offices of Sullivan &amp; Cromwell LLP and at Carter Ledyard &amp; Milburn LLP. </p>
        </div>
      )
    default:
      return null
  }
}