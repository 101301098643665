import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';


function GoogleAnalytics({setPathN} ) {

  const history = useHistory()
  const [pathN, setPathNN] = useState('')

  useEffect(() => {
    history.listen((location) => {
      if (location !== pathN) {
        setPathNN(location)
        // console.log(`You changed the page to: ${location.pathname}`)
        setPathN(location.pathname)
      }
    })
  }, [history, pathN, setPathN])

  return null;
}

export default GoogleAnalytics

