import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet'
import StripedBox from '../components/StripedBox'
import { isEmptyNullUndefined } from '../components/helper'
import './practices.css'
import { withRouter } from 'react-router-dom'
// import notice from './notice'

function NotFoundPage({ location, heading = '', innercontent = '' }) {

  const [type, setType] = useState('pllc')
  // const [picker, setPicker] = useState('pllc')

  //Set the type
  useEffect(() => {
    const noticePathname = location.pathname.split('/').join('/')
    // console.log('type is: ', noticePathname)
    setType(noticePathname)
  }, [location.pathname])

  // console.log(heading)
  // console.log(innercontent)

  return (
    <div className='notices-container'>
      <Helmet>
        <title>Page Not Found 404</title>
        <meta name="description" content='404 The resource you are looking for has been removed, has had its name changed or is temporarily unavailable.' />
      </Helmet>
      <div className='so-grid-main'>
        <StripedBox Heading={heading.length > 0 ? heading : 'Apologies...'} Content={
          <React.Fragment>
            {!isEmptyNullUndefined(innercontent) ? innercontent :
              <h2>The content you requested at: http://szenok.com{type} cannot be found.</h2>}
            <ul>
              <li>For the home page, visit <Link to={`/`} >/home</Link></li>
              <li>For a list of the firm's attorneys, visit <Link to={`/attorneys`} >/attorneys</Link></li>
              <li>For a list of the firm's news and publications, visit <Link to={`/news`} >/news</Link></li>
            </ul>
          </React.Fragment>
        } />
      </div>
    </div>
  )
}

export default withRouter(NotFoundPage)

