import React from "react";

function PrintButton({ widthx = 100, printColor = '#edeaea' }) {
  // console.log(printColor)
  // let color = onMouseOut ? '#edeaea' : '#f60'
  return (
    <svg width={widthx} viewBox='0 0 1000 1000'>
      <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="1000">
        <g >
          <g
            className='footer-right-item-top-picture'
            fill={printColor}
            stroke="#ff7f00"
            strokeWidth="20"
            transform="matrix(.1 0 0 -.1 0 511)"
          >
            <path d="M2918.3 4119.3c-303-51.3-610.8-284.4-753-568.8-102.6-212.1-125.9-359-125.9-822.9v-380h-533.8c-575.8 0-720.3-16.3-899.8-104.9-237.8-116.6-454.6-410.3-491.9-669l-14-109.6 258.8-128.2 258.8-130.5h8765l258.7 130.5 258.8 128.2-14 109.6c-37.3 261.1-247.1 550.1-487.2 666.7-184.2 90.9-324 107.2-904.5 107.2h-533.8v380c0 463.9-23.3 610.8-125.9 822.9-142.2 286.7-435.9 508.2-757.6 568.8-149.2 27.9-3988.5 27.9-4158.7 0zm4240.3-342.7c79.3-25.6 156.2-81.6 256.4-179.5 216.8-216.8 219.1-230.8 219.1-1102.6v-729.6H2365.8v729.6c0 871.8 2.3 885.8 219.1 1102.6 233.1 230.8 51.3 214.5 2405.7 216.8 1860.3 2.3 2063.1-2.4 2168-37.3z"></path>
            <path d="M109.3-267.9l7-1382.3 74.6-153.8c90.9-184.2 258.7-349.7 445.2-435.9 130.5-60.6 146.9-62.9 692.3-74.6l559.5-11.7 14-338c11.7-275.1 23.3-361.3 65.3-466.2 163.2-401 484.9-671.4 909.1-757.6 146.9-32.6 452.2-35 2202.9-30.3 2030.4 7 2030.4 7 2184.3 58.3 363.7 123.5 643.4 396.3 773.9 752.9 37.3 95.6 51.3 209.8 60.6 454.6l14 326.4 559.5 11.7 561.8 11.7 153.9 76.9c191.2 93.2 366 277.4 442.9 466.2l53.6 132.9 7 1370.7 7 1370.7-209.8-107.2-212.1-104.9H524.3l-212.1 104.9-209.8 107.2 6.9-1382.6zM1107 121.4c207.5-107.2 167.8-435.9-60.6-498.9-104.9-28-186.5-7-263.4 69.9-81.6 81.7-104.9 186.6-62.9 286.8 65.3 158.5 235.4 221.5 386.9 142.2zm6380.3-489.5c139.9-42 223.8-128.2 268.1-275.1 23.3-72.3 30.3-370.7 30.3-1181.9 0-1079.3 0-1084-53.6-1216.8-76.9-188.8-251.8-373-442.9-466.2L7133-3585H2867l-156.2 76.9c-191.1 93.2-366 277.4-442.9 466.2-53.6 130.6-53.6 149.2-60.6 1135.3-7 1046.7 4.7 1244.8 88.6 1382.3 51.3 86.3 135.2 144.5 240.1 167.8 39.6 11.7 1144.6 18.7 2454.7 21 2063 2.3 2394-2.3 2496.6-32.6zM1125.7-766.7c65.3-44.3 121.2-151.5 121.2-230.8 0-79.3-55.9-186.5-121.2-230.8-67.6-48.9-216.8-46.6-298.4 2.4-172.5 100.2-167.8 368.3 4.7 466.2 74.6 39.6 230.8 37.2 293.7-7z"></path>
            <path d="M3149.1-927.6c-60.6-62.9-67.6-130.5-18.7-195.8l44.3-60.6h3417.4l44.3 60.6c48.9 65.3 42 132.9-18.7 195.8-32.6 30.3-251.8 35-1734.4 35-1482.4 0-1701.6-4.7-1734.2-35z"></path>
            <path d="M3149.1-1860c-60.6-62.9-67.6-130.5-18.7-195.8l44.3-60.6h3417.4l44.3 60.6c48.9 65.3 42 132.9-18.7 195.8-32.6 30.3-251.8 35-1734.4 35-1482.4-.1-1701.6-4.7-1734.2-35z"></path>
            <path d="M3149.1-2792.5c-60.6-62.9-67.6-130.5-18.7-195.8l44.3-60.6h3417.4l44.3 60.6c48.9 65.3 42 132.9-18.7 195.8-32.6 30.3-251.8 35-1734.4 35-1482.4 0-1701.6-4.7-1734.2-35z"></path>
          </g>
        </g>
      </svg>
    </svg>
  );
}

export default PrintButton