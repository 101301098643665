import React, { Component } from 'react'
import {
  NavLink
} from "react-router-dom"
import { withRouter } from "react-router"
import './header-menu.css'

class HeaderMenu extends Component {

  state = {
    Home: this.props.showMenu ? 'Home' : 'nothome'
  }

  getNavLinkClass = (path) => {
    let pathname = this.props.location.pathname
    if (pathname.length > 1 && pathname.slice(-1) === '/') pathname = pathname.slice(0, -1)
    // console.log(`This is path: ${path} and this is props.location.pathname: ${this.props.location.pathname}`)
    // console.log(`This is props.location.pathname.slice(0,9): ${this.props.location.pathname.slice(0,9)}`)
    // console.log(path === this.props.location.pathname)
    return pathname === path ? 'active' : 'notactive';
  }

  getNavLinkClassForAtty = (path) => {
    if (path.slice(-1) === '/') path = path.slice(0, -1)
    return this.props.location.pathname.slice(0, 9) === path ? 'active' : 'notactive';
  }

  getNavLinkClassForHome = (path) => {
    // console.log(path, this.props.location.pathname)
    // console.log(this.props.location.pathname === path)
    // if(path.slice(-1) === '/') path = path.slice(0,-1)
    return this.props.location.pathname === path ? 'main-nav main-nav-active' : 'main-nav';
  }

  //   checkActive = (match, location) => {
  //     // console.log(match, location)
  //     //some additional logic to verify you are in the home URI
  //     if(!location) return false;
  //     const {pathname} = location;
  //     console.log(pathname);
  //     return pathname === "/";
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.showMenu !== this.props.showMenu) {
      this.setState({ Home: this.props.showMenu ? 'Home' : 'nothome' })
    }
  }


  render() {

    return (
      <div className='header-menu-container' key={this.state.Home}>
        {this.props.showMenu &&
          <ul>
            {/* <NavLink exact to="/" style={{ textDecoration: 'none' }} className='main-nav' isActive={this.checkActive} activeClassName="main-nav-active"> */}
            <NavLink to="/" style={{ textDecoration: 'none' }} className={this.getNavLinkClassForHome("/")} exact={true} >
              {/* <NavLink to="/" style={{ textDecoration: 'none' }} className={'main-nav'} exact={true} > */}
              <li className={this.getNavLinkClass("/")} onClick={() => this.props.setShowMenu()}>
                {/* <li className={'notactive'} onClick={() => this.props.setShowMenu()}> */}
                {this.state.Home}
              </li>
            </NavLink>
            <NavLink to="/practices" style={{ textDecoration: 'none' }} className='main-nav' activeClassName="main-nav-active">
              <li className={this.getNavLinkClass("/practices")} onClick={() => this.props.setShowMenu()}>
                Practices
              </li>
            </NavLink>
            <NavLink to="/attorneys" style={{ textDecoration: 'none' }} className='main-nav' activeClassName="main-nav-active"
              isActive={() => {
                // console.log(this.props.location.pathname.slice(0,9))
                if (this.props.location.pathname.slice(0, 9) === '/attorney') return true
                else return false
              }}>
              <li className={this.getNavLinkClassForAtty("/attorney")} onClick={() => this.props.setShowMenu()}>
                Attorneys
          </li>
            </NavLink>
            <NavLink to="/news" style={{ textDecoration: 'none' }} className='main-nav' activeClassName="main-nav-active">
              <li className={this.getNavLinkClass("/news")} onClick={() => this.props.setShowMenu()}>
                News/Links
          </li>
            </NavLink>
            <NavLink to="/contact" style={{ textDecoration: 'none' }} className='main-nav' activeClassName="main-nav-active">
              <li className={this.getNavLinkClass("/contact")} onClick={() => this.props.setShowMenu()}>
                Contact
          </li>
            </NavLink>
          </ul>
        }
      </div>
    )
  }
}

export default withRouter(HeaderMenu)

HeaderMenu.defaultProps = {
  showMenu: true,
}