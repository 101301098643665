import React from "react";

function PlusIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <circle
        cx="8.072"
        cy="-1038.263"
        r="8"
        fill="#f60"
        opacity="0.98"
        transform="matrix(1 0 0 -1 0 -1030.36)"
      ></circle>
      <path
        fill="#fff"
        d="M9.45 2.202H6.915c-.002 1.215 0 4.41 0 4.41s-2.75 0-4.11.003v2.533c1.36-.002 4.11-.002 4.11-.002s0 3.19-.002 4.41h2.535c.002-1.22.002-4.41.002-4.41h4.108V6.61H9.45s-.004-3.194 0-4.409"
      ></path>
    </svg>
  );
}

export default PlusIcon;
