import React from 'react'
import StripedBox from '../components/StripedBox'
import StripedImage from '../components/StripedImage'
import './home.css'

export default function Home() {
  return (
    <div className='practices-container'>
      <div className='so-grid-main'>
        <StripedBox Heading={'About Szenberg & Okun PLLC'} Content={homeContent()} />
      </div>
      <StripedImage area='so-grid-graphic' />
    </div>
  )
}


let homeContent = () => {
  return (
    <div>
      <h4>Who We Are</h4>
        Szenberg & Okun PLLC is a boutique law firm that provides corporate legal services to emerging and established companies. We deliver a level of service typically found at top white shoe law firms in a cost-effective and responsive manner. While we offer an extensive range of services, our core areas of expertise are commercial real estate, joint ventures and venture capital, financings, fund formation, and mergers & acquisitions.
      <h4>Our Philosophy</h4>
        We understand and focus on our clients' business objectives, always mindful of the big picture. Our clients trust us to provide practical counsel that looks beyond the immediate legal issues to larger business implications.
      <h4>Our Goal</h4>
        Our goal is to provide strategic, pragmatic and responsive legal counsel in each of our practice areas. We understand the goals of our clients and bring the right resources to meet the challenges they face.
    </div>
  )
}
