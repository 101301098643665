
import React, { Component } from 'react'
import { withRouter } from "react-router"
import {
  NavLink
} from "react-router-dom"
import EmailButton from '../images/emailbutton'
import PrintButton from '../images/printbutton'
import LinkedInButton from '../images/linkedin'
// import HeaderMenu from './header-menu'
import './footer.css'


class Footer extends Component {

  state = {
    showMenu: true,
    printColor: '#edeaea',
    emailColor: '#edeaea',
  }


  render() {
    // const { match, location, history } = this.props
    return (
      <div className='footer-container no-print'>
        <div className='footer-left'></div>
        <div className='footer-menu-overall-container'>
          <ul>
            <NavLink exact to="/notices/pllc-notice" style={{ textDecoration: 'none' }} className='footer-nav' activeClassName="footer-nav-active">
              <li>PLLC Notice</li>
            </NavLink>
            <NavLink exact to="/notices/attorney-notice" style={{ textDecoration: 'none' }} className='footer-nav' activeClassName="footer-nav-active">
              <li>Attorney Advertising Notice</li>
            </NavLink>
            <NavLink exact to="/notices/web-notice" style={{ textDecoration: 'none' }} className='footer-nav' activeClassName="footer-nav-active">
              <li>Web Site Notice</li>
            </NavLink>
            <li>Szenberg & Okun PLLC {'\u00A9'}2020</li>
          </ul>
        </div>
        <div className='footer-right'>

          <div className='footer-right-item' onMouseOver={() => this.setState({ printColor: '#f60' })} onMouseOut={() => this.setState({ printColor: '#edeaea' })}>
            <div className='footer-right-item-top' onClick={() => window.print()}><PrintButton widthx={20} printColor={this.state.printColor} /></div>
            <div className='footer-right-item-bottom' onClick={() => window.print()}>Print</div>
          </div>

          <div className='footer-right-item' onMouseOver={() => this.setState({ emailColor: '#f60' })} onMouseOut={() => this.setState({ emailColor: '#edeaea' })}>
            <div className='footer-right-item-top' ><a href="mailto:info@szenok.com"><EmailButton widthx={20} emailColor={this.state.emailColor} /></a></div>
            <div className='footer-right-item-bottom'><a href="mailto:info@szenok.com">Email</a></div>
          </div>

          <div className='footer-right-item'><a href="http://www.linkedin.com/company/szenberg-&amp;-okun-pllc"><LinkedInButton widthx={20} /></a></div>
        </div>
      </div>
    )
  }
}

export default withRouter(Footer)


