import React, { Component } from 'react'
import LogoSVG from '../images/SzenOkLogo.svg'
import './logo.css'

export default class Logo extends Component {
  render() {
    return (
      <div className='so-header-middle-container'>
        <div className='so-header-middle' > 
            {/* <svg viewBox='0 0 504 80'>
              {SzenokLogo()}
            </svg> */}
            <img src={LogoSVG} alt='logo1' width={'100%'} />
          </div>
      </div>
    )
  }
}


// function SzenokLogo() {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" width="504" height="88">
//       <defs>
//         <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
//           <path d="M0 0h378v66H0V0z"></path>
//         </clipPath>
//       </defs>
//       <g transform="matrix(1.33333 0 0 -1.33333 0 88)">
//         <g>
//           <g
//             fillRule="nonzero"
//             clipPath="url(#clipPath18)"
//             fontWeight="normal"
//           >
//             <text
//               fill="#f15a29"
//               fontFamily="'Poor Richard'"
//               fontSize="52"
//               transform="matrix(1 0 0 -1 174.267 25.648)"
//             >
//               <tspan x="0" y="0">
//                 &amp;
//               </tspan>
//             </text>
//             <text
//               fill="#231f20"
//               fontFamily="'Agency FB'"
//               fontSize="73"
//               transform="matrix(1 0 0 -1 220.475 9.438)"
//             >
//               <tspan x="0" y="0">
//                 O
//               </tspan>
//             </text>
//             <text
//               fill="#231f20"
//               fontFamily="'Agency FB'"
//               fontSize="50"
//               transform="matrix(1 0 0 -1 252.947 9.438)"
//             >
//               <tspan
//                 x="0 19.25 41.200001 63.049999 72.849998 91.699997 100.6 109.5"
//                 y="0"
//               >
//                 KUN pllc
//               </tspan>
//             </text>
//             <text
//               fill="#231f20"
//               fontFamily="'Agency FB'"
//               fontSize="73"
//               transform="matrix(1 0 0 -1 -4.066 9.582)"
//             >
//               <tspan x="0" y="0">
//                 S
//               </tspan>
//             </text>
//             <text
//               fill="#231f20"
//               fontFamily="'Agency FB'"
//               fontSize="50"
//               transform="matrix(1 0 0 -1 26.445 9.582)"
//             >
//               <tspan
//                 x="0 17.200001 35.049999 56.900002 78.150002 96 117.35"
//                 y="0"
//               >
//                 ZENBERG
//               </tspan>
//             </text>
//           </g>
//         </g>
//       </g>
//     </svg>
//   );
// }