import React from 'react'

export const getEducation = (ID) => {
  switch (parseInt(ID)) {
    case 1:
      return (
        <div>
          <h4>Education:</h4>
          <p>J.D., Harvard Law School, 2000</p>
          <p>B.A., University of California, Berkeley, 1997</p>
          <br />
          <h4>Bar Admissions:</h4>
          <p>New York</p>
        </div>
      )
    case 2:
      return (
        <div>
          <h4>Education</h4>
          <p>J.D., University of Pennsylvania Law School, 1993 (<em>Magna Cum Laude, Order of the Coif</em>)</p>
          <p>B.A., Touro College, 1987 (<em>Magna Cum Laude</em>)</p>
          <p>&nbsp;</p>
          <h4>Clerkship</h4>
          <p>Judge Max Rosenn, U.S. Ct. Appeals, 3d Circuit</p>
          <p>&nbsp;</p>
          <h4>Bar Admissions</h4>
          <p>New York</p>
          <p>New Jersey </p>
          <p>Pennsylvania</p>
          <p>&nbsp;</p>
          <h4>Honors</h4>
          {/* superlawyers badge */}
          <div className='no-print'>
            <div id="super_lawyers_badge" style={{ margin: 0, padding: 0, lineHeight: 1, fontSize: '1em', font: '100 0.8em/1em "Arial",sans-serif', position: 'relative', outline: 'none', border: 'none' }}>
              <div id="large_super_lawyers_badge" style={{ height: '150px', width: '180px', backgroundImage: 'url("http://i.superlawyers.com/shared/badge/lawyer/basic/large_white-orange.png")', textAlign: 'center', outline: 'none', border: 'none', lineHeight: 1, fontSize: '100%' }}>
                <table width="180px" border={0} cellPadding={0} cellSpacing={0} frame="void" rules="none" summary="true" dir="ltr" style={{ margin: 0, padding: 0, outline: 'none', border: 'none' }}>
                  <tbody><tr align="center" char="true" charoff="true" valign="bottom" style={{ height: '75px', verticalAlign: 'bottom', margin: 0, padding: 0, outline: 'none', border: 'none' }}><td align="center" colSpan={1} rowSpan={1} valign="bottom" style={{ margin: 0, padding: 0, outline: 'none', border: 'none' }}><a href="http://www.superlawyers.com/redir?r=http://www.superlawyers.com/new-york-metro/lawyer/Avi-I-Szenberg/467c597e-a816-40eb-8e00-1c6ece29c1ec.html&c=basic_largewhite-orange_badge&i=467c597e-a816-40eb-8e00-1c6ece29c1ec" rel="nofollow" style={{ height: '75px', width: '150px', display: 'block', textDecoration: 'none', margin: 0, padding: 0, textAlign: 'center', outline: 'none', border: 'none' }}>&nbsp;</a></td></tr>
                    <tr align="center" char="true" charoff="true" valign="middle" style={{ margin: 0, padding: 0, lineHeight: 1, fontSize: '100%', outline: 'none', border: 'none' }}><td style={{ height: '0px', verticalAlign: 'middle', margin: 0, padding: '0 10px', lineHeight: 1, fontSize: '100%', outline: 'none', border: 'none' }} valign="middle" align="center" colSpan={1} rowSpan={1}><a href="http://www.superlawyers.com/redir?r=http://www.superlawyers.com/new-york-metro/lawyer/Avi-I-Szenberg/467c597e-a816-40eb-8e00-1c6ece29c1ec.html&c=basic_largewhite-orange_badge&i=467c597e-a816-40eb-8e00-1c6ece29c1ec" alt="View the profile of New York Metro Real Estate Attorney Avi I. Szenberg" title="View the profile of New York Metro Real Estate Attorney Avi I. Szenberg" style={{ height: '40px', width: '150px', display: 'block', textDecoration: 'none', margin: 0, padding: 0, lineHeight: 1, textAlign: 'center', fontFamily: 'arial,sans-serif', color: 'rgb(255,145,0)', fontSize: '16px', fontWeight: 'bold', outline: 'none', border: 'none' }}>Avi I. Szenberg</a></td></tr>
                    <tr align="center" char="true" charoff="true" valign="bottom" style={{ margin: 0, padding: 0, lineHeight: 1, fontSize: '100%', outline: 'none', border: 'none' }}><td style={{ height: '26px', verticalAlign: 'bottom', margin: 0, padding: 0, lineHeight: 1, fontSize: '100%', outline: 'none', border: 'none' }} valign="bottom" align="center" colSpan={1} rowSpan={1}><a href="http://www.superlawyers.com/redir?r=http://www.superlawyers.com&c=basic_large_white-orange_badge&i=home_page" alt="Visit the official website of Super Lawyers" title="Visit the official website of Super Lawyers" style={{ height: '20px', width: '150px', display: 'block', textDecoration: 'none', margin: 0, padding: 0, lineHeight: 1, fontSize: '100%', outline: 'none', border: 'none' }}>&nbsp;</a></td></tr>
                  </tbody></table></div></div><div style={{ display: 'none' }}><img src="http://www.superlawyers.com/services/badge/beacon/467c597e-a816-40eb-8e00-1c6ece29c1ec/l/9.gif" width={1} height={1} border={0} alt='' /></div>
          </div>
        </div>
      )
    case 3:
      return (
        <div>
          <h4>Education:</h4>
          <p>J.D., Columbia Law School, 2008 </p>
          <p>B.A., Queens College, CUNY, 2003</p>
          <p>&nbsp;</p>
          <h4>Bar Admissions</h4>
          <p>New York</p>
        </div>
      )
    case 4:
      return (
        <div>
          <h4>Education:</h4>
          <p>J.D., University of Pennsylvania Law School, 2006 (<em>magna cum laude; Order of the Coif</em>) </p>
          <p>B.A., Columbia University, 2002 (<em>magna cum laude</em>) </p>
          <p>&nbsp;</p>
          <h4>Bar Admissions</h4>
          <p>New York</p>
        </div>
      )
    case 5:
      return (
        <div>
          <h4>Education:</h4>
          <p>J.D., Columbia Law School, 1997 (<em>Stone Scholar</em>)</p>
          <p>B.A., Barnard College, Columbia University, 1994 (<em>Summa Cum Laude; Phi Beta Kappa</em>)</p>
          <p>&nbsp;</p>
          <h4>Bar Admissions</h4>
          <p>New York</p>
        </div>
      )
    default:
      return null
  }
}