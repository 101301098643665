import React from 'react'

export const getTransactions = (ID) => {
  switch (parseInt(ID)) {
    case 1:
      return (
        <div>
          <h4>Representative Transactions:</h4>
          <p>Jacob's representative transactions include:</p>
          <ul>
            <li>Negotiation of Master Development Agreement for the exclusive right to develop a hotel brand in the New York and Boston MSAs.</li>
            <li>Formation of a $30 million joint venture and subsequent purchase of 17 senior living facilities for $180 million.</li>
            <li>Formation of a $60 million joint venture for the development of a luxury resort in Belize.</li>
            <li>Formation of a $15 million joint venture for the development of a boutique luxury hotel located along the Highline in Manhattan.</li>
            <li>Formation of a $40 million joint venture for the development of a luxury resort in Mexico.</li>
            <li>Development of a Master Lease structure for a series of high-scale restaurants in the United States.</li>
            <li>Represented two major REITs in connection with the sale of their respective interests in a major owner and operator of cold storage facilities.</li>
            <li>Formation of a joint venture and purchase of a hotel in San Francisco for $212 million.</li>
            <li>Represented client in acquisition of a medium-sized bank in New York.</li>
            <li>Formation of a $375 million fund for purchase of hospitality-related assets.</li>
            <li>Undertaking of a $200 million construction loan for the development of condominiums in Hawaii.</li>
            <li>Formation of a theater company joint venture for the development of Broadway productions.</li>
            <li>Represented target in $12.6 billion public acquisition.</li>
            <li>Represented purchaser in acquisition of $2.1 billion in retirement assets from an insurance company.</li>
            <li>Mezzanine financing in connection with the purchase of the General Motors building.</li>
            <li>Purchase of six Washington D.C. properties by an office REIT.</li>
            <li>Purchase of a real estate servicing company by a REIT.</li>
            <li>Representation of borrowers and lenders in over $4 billion of financing transaction, including single-asset and portfolio debt, construction financing, credit facilities and fund-level indebtedness, and acquisition financing.</li>
            <li>Representation of U.S. and foreign issuers, as well as underwriters, in over $5 billion of debt securities, both in public and 144A and Regulation S offerings.</li></ul>
        </div>
      )
    case 2:
      return (
        <div>
          <h4>Representative Transactions:</h4>
          <p>Avi's representative transactions include:</p>
          <ul>
            <li> Representation of landlords and tenants in connection with the lease of over 2,000,000 square feet of commercial space in New York City.</li>
            <li> Representation of sellers and purchasers in acquisitions and dispositions of numerous asset types, including office buildings, hotels, corporate campuses, banks, storage facilities, restaurants and cable towers. </li>
            <li> Representation of numerous borrowers, mezzanine borrowers, lenders and convertible equity lenders in billions of dollars worth of financing transactions, including:
              <ul>
                <li>Single-asset and portfolio property debt (including purchase financing, refinancing and bridge facilities, mortgage and mezzanine debt);</li>
                <li>Construction financing; and</li>
                <li>Equity fund credit facilities and fund-level indebtedness. </li>
              </ul>
            </li>
            <li>Representation of a client in connection with the sale of its health care business and subsequent lease, license and sublease of numerous manufacturing facilities and office buildings. </li>
            <li> Representation of numerous clients in tens of billions of dollars worth of mergers and acquisitions in connection with real estate assets. Involved in the negotiation and drafting of purchase and sale agreements, stock and asset purchase agreements, merger agreements and similar acquisition documents. </li>
            <li> Representation of a client in connection with the financing of its chain restaurants in the United States. </li>
            <li> Representation of investment funds in all facets of their formation and corporate structuring, including negotiating and drafting joint venture and constituent organizational documents, management agreements, and side letters. </li>
            <li> Representation of a client in connection with its joint venture with respect to, and acquisition of, the General Motors Building in New York City.</li>
            <li>Representation of a client in connection with the acquisition and financing and later sale/leaseback of container terminals in the United States. </li>
            <li> Representation of a client in connection with the purchase and lease of numerous bank branches throughout the United States. </li>
            <li>Representation of a client in connection with the buy-out of its lease and subleases in an office building in New York City. </li>
            <li>Representation of tenants in connection with the design, construction and build-out of their leased premises. &nbsp;</li>
          </ul>
        </div>
      )
    case 3:
      return (
        <div>
          <h4>Representative Transactions:</h4>
          <p>Dan's representative transactions include:</p>
          <ul>
            <li>Formation of a joint venture and subsequent purchase of a controlling interest in two multifamily apartment buildings located in the Upper West Side neighborhood of New York City, in a deal valued at over $100 million.</li>
            <li>Formation of a joint venture and subsequent purchase of a controlling interest in a multifamily apartment complex in the Tribeca neighborhood of New York City, in a deal valued at over $750 million.</li>
            <li>Formation of a $1.25 billion joint venture and subsequent purchase of a controlling stake in a nearly three million square foot portfolio of office buildings located in the Rosslyn, Virginia sub-market of Washington D.C. and subsequent refinancings of various loans secured by the portfolio.  </li>
            <li>Formation of a $1.3 billion joint venture and subsequent purchase of an office and retail building located in midtown Manhattan, including an acquisition mortgage and mezzanine financing in the amount of $900 million. </li>
            <li>Representation of the borrower in a $310 million refinancing of a hotel located in Times Square.</li>
            <li>Representation of the borrower in a $350 million construction loan for a condominium development located in the Tribeca neighborhood of New York City.</li>
            <li>Representation of the borrower in the $600 million refinancing of a large office complex located in San Francisco, California.</li>
            <li>Representation of the borrower in the $325 million refinancing of a mall located in midtown Manhattan. </li>
            <li>Representation of the borrower in the $67 million refinancing of a shopping center located in White Plains, New York.</li>
            <li>Representation of the borrower in a $200 million construction loan for the construction and development of an office building located in the Rosslyn, Virginia sub-market of Washington D.C. and the subsequent permanent takeout financing.</li>
            <li>Representation of the lender in a $175 million loan for the acquisition and redevelopment of a hotel located in midtown Manhattan.</li>
            <li>Representation of the lender in a $125 million loan for the acquisition and redevelopment of a luxury hotel located in Miami Beach, Florida. </li>
            <li>Representation of the lender in a $72 million construction loan for the ground-up development of a mixed-use residential and luxury hotel project located in Seattle, Washington. </li>
            <li>Representation of the lender in a $340 million construction loan for the ground-up development of a residential condominium project located in the Flatiron District of New York City.</li>
            <li>Representation of the purchaser in the acquisition of a public company owning a portfolio of over 135 hotels located across the United States in a deal valued at $2.2 billion, including the acquisition mortgage and mezzanine financing in the amount of $1.8 million.</li>
            <li>Representation of the seller in the $405 million sale of a hotel located in New York City.</li>
            <li>Representation of the seller in the $760 million sale of a 1.4 million square foot office building located in New York City.</li>
            <li>Representation of the seller in the $720 million sale of a 910,000 square foot office building located in New York City.</li>
            <li>Representation of the seller in the sale of a 20% interest in a mixed office and retail building in New York City, in a deal valuing the entire building at over $3.4 billion.</li>
            <li>Representation of the target company in $1.65 billion hostile public takeover battle.</li>
            <li>Representation of an investor making a minority investment in a luxury hotel located in Los Angeles, California. </li>
            <li>Representation of borrowers and lenders in over $5 billion of financing transactions, including single-asset and portfolio debt, construction financing, credit facilities and fund-level indebtedness, and mortgage and mezzanine financing.</li>
            <li>Representation of U.S. and foreign issuers, as well as underwriters, in over $3 billion of debt and equity securities, both in public and private offerings. </li>
          </ul>
        </div>
      )
    case 4:
      return (
        <div>
          <h4>Representative Transactions:</h4>
          <p>Daniel&rsquo;s multi-disciplinary practice includes representations and advice in connection with a broad array of commercial real estate and capital markets transactions and matters.</p>
          <p>
            <strong>Representative Commercial Real Estate Transactions</strong>
          </p>
          <ul>
            <li>Representation of buyer in acquisition of development site of historic Drake Hotel in New York City. </li>
            <li>Representation of buyer in $1.3 billion acquisition of holding company that owns multiple casinos, including Stratosphere Las Vegas.</li>
            <li>Representation of Toys “R” US in its issuance of $800 million in high yield bonds secured by 140 stores and distribution centers. </li>
            <li>Representation of a REIT in financings of retail and office spaces in New York City. </li>
            <li>Representation of a real estate investment fund in sale of numerous New York City office buildings.</li>
            <li>Representation of mezzanine lender in pre-packaged bankruptcy of publicly traded casino operator.</li>
            <li>Representation of a REIT and real estate investment funds in numerous purchases of distressed and performing mortgage and mezzanine notes.</li>
            <li>Representation of developers and investors in formation of joint ventures used to acquire and finance office, retail and hotel real estate assets.</li>
          </ul>
          <p>
            <strong>Representative Capital Markets Transactions and Matters</strong>
          </p>
          <ul>
            <li>Representation of issuers and underwriters in dozens of IPOs, add-on offerings, debt offerings, Rule 144A/Reg. S offerings and registered closed-end fund offerings, including representation of underwriters in connection with Twitter’s $1.8 billion IPO, Fitbit’s $730 million IPO and the IPO of one of the first special purpose acquisition companies (SPACs) to be listed on the Mexican Stock Exchange. </li>
            <li>Representation of placement agent in private placement of preferred stock of Uber and advised on the formation of access fund used for the investment.</li>
            <li>Representation of dealers in large block sales, including both prospectus and Rule 144 sales. </li>
            <li>Representation of investors in investments in venture capital transactions, including investments in convertible notes, warrants and preferred securities.</li>
            <li>Representation of dealers in OTC derivatives transactions with high net worth, hedge fund and family office counterparties across multiple asset classes, including equities, rates, FX and commodities.</li>
            <li>Representation of issuers and underwriters in shelf registrations and establishment of MTN and structured notes and CD programs. </li>
            <li>Regularly advise on securities, investment adviser, broker-dealer and OTC derivatives regulatory matters with significant expertise in Rule 144, Section 16 short swing profit rules, Section 13/16 reporting, Regulation S, registered offerings, Regulation D/JOBS Act, Regulation M, Regulation SHO, Dodd-Frank Act and CFTC regulations, FINRA Rules 5110, 5121, 5130 and 5131 and broker-dealer, investment adviser and CFTC registration and compliance issues.    </li>
          </ul>
          <p>
            <strong>Additional Representative Matters</strong>
          </p>
          <ul>
            <li>Representation of AT&T in numerous multi-billion dollar public acquisitions. </li>
            <li>Representation of Wachovia in $15.1 billion sale to Wells Fargo. </li>
            <li>Representation of National City in $5.58 billion sale to PNC. </li>
            <li>Representation of Tyco, Tyco Electronics and Covidien spin-off transactions and debt tender offers. </li>
            <li>Advise on separation of major global financial institution’s investment banking division into separate institutional and retail broker-dealers.</li>
            <li>Regularly advise on SEC, FINRA and CFTC investigations and enforcement actions. </li>
            <li>Significant expertise in U.S. laws and regulations relating to cryptocurrencies and ICOs.</li>
          </ul>
        </div>
      )
    case 5:
      return (
        <div>
          <h4>Representative Transactions:</h4>
          <p>Ms. Heino's representative transactions include:</p>
          <ul>
            <li> Representation of landlords and tenants in connection with the lease of commercial space in New York City. </li>
            <li> Representation of private and tax-exempt sellers and purchasers in acquisitions and dispositions of numerous asset types, including office buildings, hotels and restaurants. </li>
            <li> Representation of numerous borrowers and lenders in financing transactions, including:
            <ul>
              <li> Single-asset and portfolio property debt (including purchase financing and mortgage and mezzanine debt); and</li>
              <li> Construction financing. </li>
            </ul>
            </li>
            <li> Representation of investment funds in all facets of their formation and corporate structuring, including in negotiating and drafting joint venture and constituent organizational documents, management agreements, and side letters. </li>
            <li> Representation of a New York-based developer in the disposition of a multi-state portfolio of commercial properties.</li>
            <li> Representation of a not-for-profit health care corporation in connection with construction contracts for renovation and alteration projects at commercial real estate properties located throughout New York. &nbsp;</li>
          </ul>
        </div>
      )
    default:
      return null
  }
}