import React from "react";

function EmailButton({widthx=100, emailColor = '#edeaea'}) {
  // console.log('This is width: ', widthx)
  return (
    <svg width={widthx} viewBox='0 0 452.84 452.84'>
    <svg xmlns="http://www.w3.org/2000/svg" width="452.84" height="452.84">
      <g fill={emailColor} className='footer-right-item-top-picture' stroke="#ff7f00">
        <path d="M449.483 190.4l.001-.001-57.824-38.335V23.93a7.5 7.5 0 00-7.5-7.5H68.67a7.5 7.5 0 00-7.5 7.5v128.143L3.356 190.399l.001.002A7.49 7.49 0 000 196.65v232.26a7.5 7.5 0 007.5 7.5h437.84a7.5 7.5 0 007.5-7.5V196.65a7.493 7.493 0 00-3.357-6.25zM61.17 216.629L37.645 204.15H61.17v12.479zM15 209.118l172.475 91.49L15 414.935V209.118zm211.417 83.671L420.454 421.41H32.381l194.036-128.621zm38.945 7.82l172.477-91.491v205.821l-172.477-114.33zM391.66 204.15h23.536l-23.536 12.484V204.15zm28.794-15H391.66v-19.09l28.794 19.09zM376.66 31.43v193.161l-125.527 66.586-20.573-13.637a7.499 7.499 0 00-8.287 0l-20.57 13.635L76.17 224.586V31.43h300.49zM61.17 189.15H32.388l28.782-19.08v19.08z"></path>
        <path d="M226.415 213.671h59.754a7.5 7.5 0 000-15h-59.754c-28.813 0-52.254-23.441-52.254-52.254v-2.213c0-28.813 23.441-52.254 52.254-52.254s52.254 23.441 52.254 52.254v5.533c0 6.237-5.074 11.312-11.312 11.312s-11.312-5.074-11.312-11.312v-10.512c0-17.864-14.533-32.398-32.397-32.398s-32.397 14.533-32.397 32.398c0 17.864 14.533 32.397 32.397 32.397 8.169 0 15.636-3.045 21.34-8.052 4.644 7.483 12.932 12.478 22.369 12.478 14.508 0 26.312-11.803 26.312-26.312v-5.533c0-37.084-30.17-67.254-67.254-67.254s-67.254 30.17-67.254 67.254v2.213c0 37.085 30.17 67.255 67.254 67.255zm-2.767-57.049c-9.593 0-17.397-7.804-17.397-17.397s7.805-17.398 17.397-17.398 17.397 7.805 17.397 17.398-7.804 17.397-17.397 17.397z"></path>
      </g>
    </svg>
    </svg>
  );
}

export default EmailButton;
