import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { isEmptyNullUndefined } from '../components/helper'
import './attorneysTable.css'

export default function AttorneysTable(props) {

  const [attorneys, setAttorneys] = useState([])
  const history = useHistory()

  //Set the attorney
  useEffect(() => {
    setAttorneys(require('../attorney-information/attorneys.json'))
  }, [])

  let linkifier = (ID, event) => {
    // console.log('This is event: ', event.type)
    let found = attorneys.find(ee => ee.ID === ID)
    if (isEmptyNullUndefined(found)) return null
    else history.push(`/attorney/${found.EFName}_${found.ELName}`)
  }

  let linkifierforfirefox = (ID, event) => {
    // console.log('This is event: ', event.type)
    let found = attorneys.find(ee => ee.ID === ID)
    if (isEmptyNullUndefined(found)) return null
    else return found
  }

  return (
    <div className='so-table-container'>
      {/* Delete this next one; it's just here for testing firefox purposes */}

      {/* {props.isFirefox ?
        <div style={{ cursor: 'pointer', border: '2px solid red' }} onClick={() => console.log('yo')}>Test{props.isFirefox ? 'yes this is firefox' : 'not firefox'}</div>
        : null} */}
      <div className='table-responsive'>
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name Name</th>
              <th>Email</th>
              <th>Telephone</th>
            </tr>
          </thead>
          <tbody>
            {/* {!props.isFirefox ? attorneysTableMaker(attorneys, linkifier) : attorneysTableMakerForFirefox(attorneys, linkifierforfirefox)} */}
            {!true ? attorneysTableMaker(attorneys, linkifier) : attorneysTableMakerForFirefox(attorneys, linkifierforfirefox)}
          </tbody>
        </table>
      </div>
    </div>
  )
}


let attorneysTableMaker = (attorneys, linkifier) => {
  return (
    attorneys.map(attorney => {
      return (
        <tr key={`${attorney.ID}`} >
          <td className='pointable' onClick={(event) => { event.preventDefault(); linkifier(attorney.ID, event) }}>{attorney.EFName}</td>
          <td className='pointable' onClick={(event) => { event.preventDefault(); linkifier(attorney.ID) }}>{attorney.ELName}</td>
          <td className='pointable'><a href={`mailto:${attorney.EEMail}`}>{attorney.EEMail}</a></td>
          <td >{`(${attorney.ETel.toString().substring(0, 3)}) ${attorney.ETel.toString().substring(3, 6)}-${attorney.ETel.toString().substring(6, 10)}`}</td>
        </tr>
      )
    })
  )
}

let attorneysTableMakerForFirefox = (attorneys, linkifier) => {
  return (
    <React.Fragment>
      {/* <tr data-link="row" className="rowlink">
        <td><a href="Avi_Szenberg.html">Avi</a></td>
        <td><a href="Avi_Szenberg.html">Szenberg</a></td>
        <td>(212) 904-1430</td>
      </tr > */}
      {attorneys.map(attorney => {
        // let attorney = likifier(attorney)
        return (
          <tr key={`${attorney.ID}`} >
            <td className='pointable'> <Link to={`/attorney/${attorney.EFName}_${attorney.ELName}`} style={{ textDecoration: 'none' }} className='main-nav'>{attorney.EFName}</Link></td>
            <td className='pointable'> <Link to={`/attorney/${attorney.EFName}_${attorney.ELName}`} style={{ textDecoration: 'none' }} className='main-nav'>{attorney.ELName}</Link></td>
            <td className='pointable'><a href={`mailto:${attorney.EEMail}`}>{attorney.EEMail}</a></td>
            <td >{`(${attorney.ETel.toString().substring(0, 3)}) ${attorney.ETel.toString().substring(3, 6)}-${attorney.ETel.toString().substring(6, 10)}`}</td>
          </tr>
        )
      })}
    </React.Fragment>
  )
}
