import React from 'react'

export default function picturePicker() {

  const pictures = require('../images/images.json')
  let picture = pictures[Math.floor(Math.random() * pictures.length)].Name

  return (
      <img src={require(`../images/${picture}`)} alt='' />
  )
}
