import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'

import './App.css';
import Header from './layout/header'
import Logo from './layout/logo'

import Home from './layout/home'
import Attorneys from './layout/attorneys'
import Attorney from './layout/attorney'
import Practices from './layout/practices'
import News from './layout/news'
import Contact from './layout/contact'
import Footer from './layout/footer'
import Notice from './layout/notice'
import NotFoundPage from './layout/not-found-page'
import GoogleAnalytics from './components/GoogleAnalytics'

// //This part here initializes the Google Analytics
const snap = navigator.userAgent !== 'ReactSnap';
const production = process.env.NODE_ENV === 'production';
if (production && snap) { ReactGA.initialize('UA-36739199-1') }
const browserHistory = createBrowserHistory()
browserHistory.listen((location, action) => {
  if (production && snap) ReactGA.pageview(location.pathname + location.search)
})

function App() {


  const [pathN, setPathN] = useState('')

  // //This part here initializes the Google Analytics part 2
  useEffect(() => {
    if (production && snap) ReactGA.pageview(window.location.pathname + window.location.search)
    // ReactGA.pageview('/attorney/Jacob_Okun')
    // ReactGA.pageview('/attorney/Avi_Szenberg')
    // ReactGA.pageview('/attorney/Daniel_Muller')
    // ReactGA.pageview('/attorney/Daniel_Hartstein')
  }, [])

  useEffect(() => {
    // console.log('PathN: ', pathN)
    if (production && snap) ReactGA.set({ page: pathN })
    if (production && snap) ReactGA.ga('send', 'pageview')
  }, [pathN])

  return (
    <Router >
      <div className="App">
        <Helmet>
          <meta charset="utf-8" />
          <title>Szenberg & Okun PLLC</title>
          {window.location.host.slice(0, 4).toLowerCase() === 'test' ? <meta name="robots" content="noindex" /> : <meta name="google-site-verification" content="mYF8EQqMi-F0HB41gLZnm4YBu3r6AFYWHvEADjphswM" />}
          <meta name="description" content="Szenberg & Okun PLLC is a boutique law firm that provides corporate legal services to emerging and established companies." />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
        <div className='content-container'>
          <div className='content-logo'>
            <Logo />
          </div>
          <div className='content-header'>
            <Header />
          </div>
          <div className='content-main'>
            <Switch>
              {/* This is to get rid of the trailing slash */}
              <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
              {/* <Redirect from='/attorneys/' to="/attorneys" />
              <Redirect from='/practices/' to="/practices" />
              <Redirect from='/news/' to="/news" />
              <Redirect from='/contact/' to="/contact" /> */}
              <Route exact strict path="/" render={(props) => <Home />} />
              <Route path="/home" render={(props) => <Home />} />
              <Route path="/news" render={(props) => <News />} />
              <Route path="/attorneys" render={(props) => <Attorneys />} />
              {/* <Route path="/attorneys/" exact strict render={(props) => <Attorneys />} /> */}
              <Route path="/attorney/" strict render={(props) => <Attorney />} />
              <Route path="/practices" render={(props) => <Practices />} />
              <Route path="/contact" render={(props) => <Contact />} />
              <Route path="/notices" render={(props) => <Notice />} />
              {/* These redirects are to fix Google still linking to the old site */}
              <Redirect from='/Jacob_Okun.html/' to="/attorney/Jacob_Okun" />
              <Redirect from='/Avi_Szenberg.html/' to="/attorney/Avi_Szenberg" />
              <Redirect from='/Daniel_Muller.html/' to="/attorney/Daniel_Muller" />
              <Redirect from='/Daniel_Hartstein.html/' to="/attorney/Daniel_Hartstein" />
              <Redirect from='/Attorneys.html/' to="/attorneys" />
              <Redirect from='/Contact.html/' to="/contact" />
              <Redirect from='/News.html/' to="/news" />
              {/* This is to send everything else to the 404 page */}
              <Route path='/' component={NotFoundPage} />
            </Switch>

          </div>
          <div className='content-footer'>
            <Footer />
          </div>
        </div>
      </div>
      <Route render={(props) => <GoogleAnalytics trackingId={'UA-36739199-1'} setPathN={setPathN} />} />
    </Router>
  );
}

export default App;
