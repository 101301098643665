import React from 'react'
import { Helmet } from 'react-helmet'
import StripedBox from '../components/StripedBox'
import StripedImage from '../components/StripedImage'
import './practices.css'


export default function News() {
  return (
    <div className='practices-container'>
      <Helmet>
        <title>News/Links</title>
        <meta name="description" content="Szenberg & Okun publications and news." />
      </Helmet>
      <div className='so-grid-main'>
        <StripedBox Heading={'News'} Content={news()} />
        <StripedBox Heading={'Recent Articles'} Content={articles()} />
        <StripedBox Heading={'Useful Sites'} Content={sites()} />
      </div>
      <StripedImage area='so-grid-graphic' />
    </div>
  )
}


export const news = () => {
  return (
    <div>
      <ul>
        <li><a href="/files/GouldInvestors153East96thStreet.pdf" download>Gould Investors buys UES rental building </a></li>
        <li><a href="/files/News--DanielHartsteinJoinsSzenberg&OkunPLLC.pdf" download>Daniel Hartstein joins Szenberg &amp; Okun PLLC</a></li>
        <li><a href="/files/News--DanMullerJoinsSzenberg&amp;OkunPLLC.pdf" download>Daniel J. Muller joins Szenberg &amp; Okun PLLC</a></li>
        <li><a href="/files/NYLJ--JudgeRejectsVerizonSettlement.pdf" download>Judge Rejects Verizon Settlement</a></li>
        <li><a href="/files/NewYorkJudgeTossesVerizonSettlementAsMisuseOfCorporateAssetsForbes.pdf" download>New York Judge Tosses Verizon Settlement As `Misuse Of Corporate Assets'</a></li>
        <li><a href="/files/CommercialLitigationInsiderPlaintiffsSeekApprovalofVerizonVodafoneClassActionAccord(December4,2014).pdf" download>Plaintiffs Seek Approval of Verizon Vodafone Class Action Accord</a></li>
        <li><a href="/files/GibsonDunnSzenbergGuide$85MNYRealEstateFinancingLaw360Announcement.pdf" download>Gibson Dunn, Szenberg Guide $85M NY Real Estate Financing</a></li>
        <li><a href="/files/CommercialObserverBlackstoneLends85m.pdf" download>Blackstone Lends $85M On Tavros' Meatpacking Buy</a></li>
      </ul>
    </div>
  )
}

export const articles = () => {
  return (
    <div>
      <ul>
        <li><a href="/files/ClientAlertVarjabedian.pdf" download>CLIENT ALERT
        In a Break from Other Circuits, the Ninth Circuit Holds that Section
        14(e) of the Exchange Act Requires Only a Showing of Negligence,
Setting the Stage for Potential Supreme Court Resolution.</a> </li>
        <li><a href="/files/ClientAlertCrypto.pdf" download>CLIENT ALERT
Senior SEC Staff Member’s Remarks Provide Guidance on Scope of SEC’s Jurisdiction Over Cryptocurrency Transactions.</a></li>
        <li><a href="/files/PracticeNoteRealEstateFundInvestmentAdvisersAct(final).pdf" download>PRACTICE NOTE: Real Estate Fund Managers - Potential Investment Advisers Act Requirements.</a> </li>

      </ul>
    </div>
  )
}


export const sites = () => {
  return (
    <div>
      <h4>Corporate Formation</h4>
      <ul>
        <li><a href="http://sos.delaware.gov/sos.shtml">Delaware Secretary of State Website</a>.        </li>
        <li><a href="https://icis.corp.delaware.gov/Ecorp/EntitySearch/NameSearch.aspx">Delaware Corporation Name Search.</a></li>
        <li><a href="https://www.dos.ny.gov/">New York Department of State Website.</a></li>
        <li><a href="http://www.dos.ny.gov/corps/bus_entity_search.html">New York Corporation Name Search.</a></li>
      </ul>
      <h4>State Laws and Regulations; Miscellaneous</h4>
      <ul>
        <li><a href="https://www.nycourts.gov/lawlibraries/nycodesstatutes.shtml">New York Law Codes</a>.</li>
        <li><a href="http://www.ag.ny.gov/">New York State Office of the Attorney General</a>.</li>
        <li><a href="http://www1.nyc.gov/">New York City Site</a>.</li>
        <li><a href="http://a836-acris.nyc.gov/CP/">New York ACRIS</a>.</li>
        <li><a href="http://codes.findlaw.com/ny/limited-liability-company-law/">New York Limited Liability Company Law</a>.</li>
        <li><a href="http://law.justia.com/codes/new-york/2010/bsc">New York Business Corporation Law</a>.</li>
        <li><a href="http://delcode.delaware.gov/title6/c018/index.shtml">Delware Limited Liability Company Act</a>.</li>
        <li><a href="http://delcode.delaware.gov/title8/c001/index.shtml">Delaware General Corporation Law</a>.</li>
      </ul>

      <h4>Federal Laws and Regulations; Miscellaneous</h4>
      <ul>
        <li><a href="http://uscode.house.gov/search/criteria.shtml">U.S. Code</a>.</li>
        <li><a href="http://www.ecfr.gov">U.S. Code of Federal Regulations</a>.</li>
        <li><a href="http://www.uspto.gov/">U.S. Patent and Trademark Office</a>.</li>
        <li><a href="http://www.state.gov/">U.S. Department of State</a>.</li>
        <li><a href="https://www.cia.gov/library/publications/the-world-factbook/">CIA Factbook</a>.</li>
        <li><a href="http://www.firstgov.gov">FirstGov:  Reference Shelf</a>.</li>
        <li><a href="http://www.sec.gov/edgar/searchedgar/companysearch.html">SEC Company Filings Search</a>.</li>
      </ul>
      <h4>Other</h4>
      <ul>
        <li><a href="http://www.usps.com/zip4/">USPS:  Zip Code Lookup</a>.</li>
        <li><a href="http://www.craigball.com/phonefind.html">Craig's Phone Finder</a>.</li>
        <li><a href="http://www.hoovers.com/">Hoover's Corporate Information</a>.</li>
        <li><a href="http://www.martindale.com/">Martindale-Hubble</a>.</li>
        <li><a href="http://www.ratesfx.com/rates/">Currency Exchange Rates</a>.</li>
        <li><a href="http://blogs.law.harvard.edu/corpgov/">Harvard Corporate Law Blog</a>.</li>
      </ul>
    </div>
  )
}


