import React from 'react'
import { Helmet } from 'react-helmet'
import StripedBox from '../components/StripedBox'
import StripedImage from '../components/StripedImage'
import CollapseComponent from '../components/collapseComponent'
import './practices.css'

export default function Practices() {
  return (
    <div className='practices-container'>
      <Helmet>
        <title>Practices</title>
        <meta name="description" content="Szenberg & Okun specializes in commercial real estate, joint ventures, financing and securities." />
      </Helmet>
      <div className='so-grid-main'>
        <StripedBox Heading={'Practices'} Content={practicesContent()} />
      </div>
      <StripedImage area='so-grid-graphic' />
    </div>
  )
}


let practicesContent = () => {
  return (
    <div>
      <CollapseComponent Heading={'Commercial Real Estate'} Content={CREContent()} />
      <CollapseComponent Heading={'Joint Ventures and Venture Capital'} Content={JVContent()} />
      <CollapseComponent Heading={'Finance'} Content={FinanceContent()} />
      <CollapseComponent Heading={'Fund Formation'} Content={FundFormationContent()} />
      <CollapseComponent Heading={'Capital Markets'} Content={CapMarketsContent()} />
      <CollapseComponent Heading={'OTC Derivatives'} Content={OTContent()} />
      <CollapseComponent Heading={'Securities Regulation'} Content={SecuritiesContent()} />
      <CollapseComponent Heading={'Mergers and Acquisitions'} Content={MAContent()} />
      <CollapseComponent Heading={'General Corporate Counseling'} Content={GCContent()} />
    </div>
  )
}

let CREContent = () => {
  return (
    <ul >
      <li>Acquisitions and Dispositions </li>
      <li className='circle'>Leasing</li>
      <ul >
        <li>Commercial Leasing and Subleasing</li>
        <li>Ground Leases</li>
        <li>Triple Net Leases</li>
      </ul>
      <li>Development and Construction</li>
      <li className='circle'>Asset Classes</li>
      <ul>
        <li>Office, Retail &amp; Mixed Use</li>
        <li className='circle'>Hospitality</li>
        <ul >
          <li>Hotel Management Agreements</li>
          <li>Hotel Franchise Agreements</li>
        </ul>
        <li>Residential &amp; Multi-Family</li>
        <li>Cooperatives and Condominiums</li>
        <li>Senior &amp; Assisted Living Facilities</li>
        <li>Parking Facilities</li>
        <li>Restaurants</li>
      </ul>
      <li>Portfolio Transactions</li>
      <li>International Real Estate Transactions</li>
      <li>Real Estate Investment Trusts REITs) </li>
      <li>Real Estate Workouts,Restructuring &amp; Foreclosures</li>
    </ul>
  )
}

let JVContent = () => {
  return (
    <ul >
      <li>Operating and Partnership Agreements</li>
      <li>Venture Financing Term Sheets and Transaction Documents</li>
      <li>Developing Financing Strategy, Business Plans, Private Placement Memoranda and Investor Presentations</li>
      <li>Represent Operating Companies as well as Capital  Investors </li>
    </ul>
  )
}

let FinanceContent = () => {
  return (
    <ul >
      <li>Credit Agreements and Revolving Commercial Loans</li>
      <li>Acquisition and Disposition Financing</li>
      <li className='circle'>Real Estate Structures</li>
      <ul >
        <li>Mortgage Financing</li>
        <li>Mezzanine Financing</li>
        <li>Construction Financing</li>
      </ul>
      <li>Recourse and Nonrecourse Guaranties</li>
      <li>Intercreditor Agreements</li>
      <li className='circle'>Distressed Debt Transactions</li>
      <ul >
        <li>Forbearance Arrangements</li>
        <li>Restructurings and Workouts</li>
        <li>Loan Workouts</li>
        <li>Secured Creditors </li>
      </ul>
      <li>Commitment Letters, Debt Financing Term Sheets and Loan And Security Documents</li>
      <li>Loan and Security Structure and Strategy</li>
      <li>Coordination of Due Diligence Process and Review </li>
    </ul>
  )
}

let FundFormationContent = () => {
  return (
    <ul >
      <li>Developing Financing Strategy, Business Plans, Private Placement Memoranda and Investor Presentations</li>
      <li>Partnership and Side Letter Agreements </li>
      <li>Fund Governance and Administration </li>
      <li>Formation of General Partner Entities </li>
      <li>Management Compensation Arrangements</li>
    </ul>
  )
}

let CapMarketsContent = () => {
  return (
    <ul >
      <li className='circle'>SEC Registered Offerings </li>
      <ul >
        <li>Draft registration statements and prospectuses</li>
        <li>Due diligence</li>
        <li>Draft and negotiate underwriting agreements</li>
        <li>SEC filings</li>
      </ul>
      <li className='circle'>Private placements and exempt offerings (Regulation D/144A/Regulation S)</li>
      <ul >
        <li>Draft private placement memorandum and other offering documentation</li>
        <li>Review and advise on requirements for registration exemptions</li>
        <li>Draft and negotiate purchase agreements and other distribution agreements</li>
        <li>JOBS Act </li>
        <li>PIPEs</li>
      </ul>
      <li className='circle'>Secondary Market and Private Transactions</li>
      <ul >
        <li>Rule 144/legend removal</li>
        <li>Block trades</li>
        <li>Prospectus sales</li>
        <li>Stock purchase agreements</li>
      </ul>
      <li>Crowdfunding and Regulation A+ offerings</li>

    </ul>
  )
}

let OTContent = () => {
  return (
    <ul >
      <li>Structure swaps, options, forwards and other OTC derivatives transactions across multiple asset classes</li>
      <li>Represent dealers and end-users</li>
      <li>ISDA Master Agreements, schedules and credit support annexes</li>
      <li>Long Form Confirmations</li>
      <li className='circle'>Advise on Commodity Exchange Act, as amended by Dodd-Frank, and CFTC and NFA Regulations</li>
      <ul >
        <li>Swap dealer, CPO, CTA, FCM and IB registration </li>
        <li> End-user issues</li>
        <li>External business conduct rules </li>
        <li>Reporting, clearing, SEF trading and uncleared margin rules </li>
      </ul>
      <li className='circle'>Hedging and monetization transactions</li>
      <ul >
        <li>Restricted and control securities</li>
        <li>Corporate insiders and affiliates</li>
      </ul>
    </ul>
  )
}

let SecuritiesContent = () => {
  return (
    <ul >
      <li>Expertise in Securities Act, Exchange Act, Investment Advisers Act and FINRA Rules</li>
      <li>Investment adviser and broker-dealer registration and compliance</li>
      <li>Advice on Rule 144 sales and Section 16 short swing profit rules</li>
      <li>Section 13 and Section 16 reporting</li>
      <li>Insider trading, material non-public information and 10b5-1 plans</li>
      <li>SEC/FINRA examinations</li>
    </ul>
  )
}
let MAContent = () => {
  return (
    <ul >
      <li>Term sheet and Transaction Structuring</li>
      <li>Private Acquisitions and Private Equity </li>
      <li>Stock Purchase Agreements</li>
      <li>Coordination of Due Diligence Process and Review </li>
    </ul>
  )
}
let GCContent = () => {
  return (
    <ul >
      <li>Choice of Business Entity (i.e. corporations, LLC’s, etc.) and Capitalization Structure</li>
      <li>Compliance with Corporate Governance Laws and Regulations</li>
      <li>Stock Option Plan Formation, Implementation and Maintenance</li>
    </ul>
  )
}