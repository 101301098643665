import React from "react";

function MinusSVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
      <circle
        cx="8.072"
        cy="-1038.263"
        r="8"
        fill="#f60"
        opacity="0.98"
        transform="matrix(1 0 0 -1 0 -1030.36)"
      ></circle>
      <path
        fill="#fff"
        strokeDasharray="null"
        strokeLinecap="null"
        strokeLinejoin="null"
        strokeWidth="null"
        d="M2.797 6.624H13.568000000000001V9.149999999999999H2.797z"
      ></path>
    </svg>
  );
}

export default MinusSVG;
