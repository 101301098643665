import React, { Component } from 'react'
import { withRouter } from "react-router"
import HeaderMenu from './header-menu'
import { Collapse } from 'react-collapse'
import './header.css'


class Header extends Component {

  state = {
    showMenu: false,
  }

  setShowMenu = () => this.setState({showMenu: false})

  render() {
    // const { match, location, history } = this.props
    return (
      <div className='header-container no-print'>
        <div className='header-graphic-phone'>
          <div className='header-graphic left'></div>
          <button className='navbutton' onClick={() => this.setState({ showMenu: !this.state.showMenu })}>Menu</button>
        </div>
        <div className='header-graphic left'></div>
        <div className='header-menu-overall-container'>
          {/* <Collapse isOpened={this.state.showMenu || false}> */}
            <HeaderMenu showMenu={true} setShowMenu={this.setShowMenu}/>
          {/* </Collapse> */}
        </div>
        <div className='header-menu-overall-container-phone'>
          <Collapse isOpened={this.state.showMenu || false}>
            <HeaderMenu showMenu={true} setShowMenu={this.setShowMenu}/>
          </Collapse>
        </div>
        <div className='header-graphic right'></div>
      </div>
    )
  }
}

export default withRouter(Header)


