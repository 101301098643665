// All this is just for the meta descriptions for each attorney page
// Better would be to get this from the bios page, but then I would have to reformat it since it's currently in html format.

export const getAttyDescription = (ID) => {
  switch (parseInt(ID)) {
    case 1:
      return (
        `Jacob is a co-founder of Szenberg & Okun PLLC.  Jacob's practice focuses on general corporate practice, with an emphasis on real estate aquisition and disposition, mergers and acquisitions, financings, joint ventures and other corporate transactions. He represents emerging growth and mature companies.`
      )
    case 2:
      return (
        `Avi is a co-founder of Szenberg & Okun PLLC. Avi handles domestic and international corporate matters of all types, with an emphasis on commercial real estate transactions, mergers and acquisitions, joint ventures, office and ground leases and myriad other corporate and contractual matters and transactions.`
      )
    case 3:
      return (
        `Daniel is a Partner at Szenberg & Okun PLLC.  Daniel's practice focuses on general corporate practice, with an emphasis on real estate acquisitions and dispositions, secured financings, preferred equity investments, joint ventures and other corporate transactions.`
      )
    case 4:
      return (
        `Daniel is a Partner at Szenberg & Okun PLLC. Daniel's practice focuses on commercial real estate transactions, including acquisitions and dispositions, financings, joint ventures and fund formation, securities offerings and transactions, broker-dealer, investment adviser, securities and derivatives regulatory matters and other corporate transactions and matters.`
      )
    case 5:
      return (
        `Ms. Heino is counsel at Szenberg & Okun PLLC. She specializes in corporate matters of all types, with an emphasis on commercial real estate transactions.`
      )
    default:
      return null
  }
}