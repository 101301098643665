import React, { Component } from 'react'
import { withRouter } from "react-router"

import { Helmet } from 'react-helmet'
import PicturePicker from '../components/picturePicker'
import AttorneysTable from '../components/attorneysTable'
import './attorneys.css'

class Attorneys extends Component {


  render() {
    const isFirefox = typeof InstallTrigger !== 'undefined'
    // console.log('Is this firefox? ', isFirefox)

    return (
      <div>
      <div className='attorneys-container'>
        <Helmet>
          <title>Lawyers</title>
          <meta name="description" content="Szenberg & Okun PLLC success is the result of its lawyers, each of whom have deep experience in their field." />
        </Helmet>
        {/* Header */}
        <div className='so-attorneys-grid-main-head' onClick={() => console.log('trying...')}>Experienced, Diligent and Flexible</div>
        {/* Stripe */}
        <div className='so-attorneys-grid-stripe'></div>
        <div className='so-attorneys-grid-secondary-stripe'></div>
        {/* Attorneys */}
        <div className='so-attorneys-grid-attorneys'>
          <p>Our attorneys have previously served as general counsel and as outside counsel to a wide variety of    companies. We provide our clients with excellent service without incurring   white shoe law firm rates. We have represented and advised a broad range of clients on complex corporate matters.</p>
          <AttorneysTable isFirefox={isFirefox} />
        </div>
        <div className='so-attorneys-grid-main-overall'>    </div>
        {/* Secondary */}
        <div className='so-attorneys-grid-secondary-overall'>    </div>
        <div className='so-attorneys-grid-secondary-head'></div>
        <div className='so-attorneys-grid-secondary'><PicturePicker /></div>
        <div className='so-attorneys-grid-secondary-border'></div>
      </div>
      </div>
    )
  }
}

export default withRouter(Attorneys)