import React from 'react'
import PicturePicker from '../components/picturePicker'
import './StripedImage.css'

export default function StripedImage({ header = 'Yo', imageSrc = 'HomeGraphic3.jpg', area = 'so-grid-graphic' }) {
  return (
    <React.Fragment>

      <div className={area}>
        <div className='stripedimage-container'>
          {/* <div className='stripedimage-image'> */}
          <PicturePicker />
          {/* </div> */}
        </div>
      </div>
      <div className={area}>
        <div className='stripedimage-frost'>{header}</div>
        <div className='stripedimage-stripe'></div>
      </div>

    </React.Fragment>
  )
}
