import React from 'react'

export const getProBono = (ID) => {
  switch (parseInt(ID)) {
    case 1:
      return (
        <div>
        </div>
      )
    case 2:
      return (
        <div>
          <h4>Pro Bono:</h4>
          <p>For years, Avi has also dedicated significant amount of time to pro bono and community projects, including in his capacity as President of the  Yad Nathan Fund and of Mishkan Moshe. The New York State Bar Association, in recognition of such work, honored Avi as an Empire State Counsel.</p>
        </div>
      )
    case 3:
      return (
        <div>
        </div>
      )
    case 4:
      return (
        <div>
        </div>
      )
    case 5:
      return (
        <div>
        </div>
      )
    default:
      return null
  }
}