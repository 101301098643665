import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router"
import { Helmet } from 'react-helmet'
import { isEmptyNullUndefined } from '../components/helper'
import './attorney.css'
import { getAttyDescription } from '../attorney-information/helmetAttyDescriptions'
import { getBio } from '../attorney-information/bios'
import { getTransactions } from '../attorney-information/transactions'
import { getEducation } from '../attorney-information/education'
import NotFoundPage from './not-found-page'
import { getProBono } from '../attorney-information/probono'
import VCFCreator from '../attorney-information/vcfCreator'

function Attorney({ location }) {

  const [attorney, setAttorney] = useState({ ID: 0 })
  const [mounted, setMounted] = useState(false)

  //Set the attorney
  useEffect(() => {
    const attorneyPathname = location.pathname.split('/')[2].split('_')
    // console.log('attorney is: ', attorneyPathname)
    if (isEmptyNullUndefined(attorneyPathname)) return
    else {
      const attorneys = require('../attorney-information/attorneys.json')
      setMounted(true)
      let foundattorney = attorneys.find(lawyer => lawyer.EFName.toLowerCase() === attorneyPathname[0].toLowerCase() && lawyer.ELName.toLowerCase() === attorneyPathname[1].toLowerCase())
      // console.log(attorneys)
      if (isEmptyNullUndefined(foundattorney)) return
      else setAttorney(foundattorney)
    }
  }, [location.pathname])

  return (!mounted ? null : attorney.ID === 0 ? <NotFoundPage heading={'No such attorney'} innercontent={<h2>Attorney ({location.pathname.split('/')[2]}) not found...</h2>} /> :
    <React.Fragment>
      <div className='attorney-container'>
        <Helmet>
          <title>{attorney.EFName} {attorney.ELName}</title>
          <meta name="description" content={getAttyDescription(attorney.ID)} />
        </Helmet>
        <div className='so-attorney-grid-main-overall'>    </div>
        {/* Header */}
        <div className='so-attorney-grid-main-head'>{`${attorney.EFName} ${attorney.ELName}`}</div>
        {/* Stripe */}
        <div className='so-attorney-grid-stripe'></div>
        <div className='so-attorney-grid-secondary-stripe'></div>
        {/* Picture */}
        <div className='so-attorney-grid-main-pic'>{!isEmptyNullUndefined(attorney.EImage) ? <img src={require(`../images/${attorney.EImage}`)} alt='' /> : null}</div>
        {/* <div className='so-attorney-grid-main-pic'><img src={attorney.EPicture} alt='' height='150px'/></div> */}
        {/* Contact */}
        <div className='so-attorney-grid-main-contact'>{contactContent(attorney)}</div>
        {/* Bio */}
        <div className='so-attorney-grid-main-bio'>{getBio(attorney.ID)}</div>
        {/* Transactions */}
        <div className='so-attorney-grid-main-transactions'>{getTransactions(attorney.ID)}</div>
        {/* Transactions */}
        <div className='so-attorney-grid-main-probono'>{getProBono(attorney.ID)}</div>
        {/* Secondary */}
        <div className='so-attorney-grid-secondary-overall'>    </div>
        <div className='so-attorney-grid-secondary-head'>Education</div>
        <div className='so-attorney-grid-secondary'>{getEducation(attorney.ID)}</div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Attorney)

let contactContent = (attorney) => {
  // console.log(attorney)
  return (
    <div>
      <h4 >Contact:</h4>
      152 West 57th Street, 23rd Fl<br />
      New York, NY 10019 <br />
      {`(${attorney.ETel.toString().substring(0, 3)}) ${attorney.ETel.toString().substring(3, 6)}-${attorney.ETel.toString().substring(6, 10)}`} direct<br />
      (518) 244-8188 fax <br />
      {<div className='pointable'><a href={`mailto:${attorney.EEMail}`}>{attorney.EEMail}</a></div>}
      {<div className='pointable'
        onClick={() => {
          let vcard = VCFCreator(attorney) //.getFormattedString()
          const url = window.URL.createObjectURL(new Blob([vcard]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${attorney.EFName}${attorney.ELName}.vcf`);
          document.body.appendChild(link);
          link.click()
        }} >
        <img alt="" src={require(`../images/i-vcard.gif`)} width="22" height="13" style={{ paddingRight: '5px' }} />
        VCard
        </div>}
    </div>
  )
}
