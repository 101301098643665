import React from 'react'
import './StripedBox.css'

export default function StripedBox({ Heading = '', Content = null }) {
  return (
    <div className='stripedbox-container'>
      <div className='stripedbox-header'>
        {Heading}
      </div>
      <div className='stripedbox-stripe'>
      </div>
      <div className='stripedbox-content'>
        {Content}
      </div>
    </div>
  )
}
