import React, { Component } from 'react'
import { withRouter } from "react-router"
import { Helmet } from 'react-helmet'
import PicturePicker from '../components/picturePicker'
import './attorneys.css'
import './contact.css'

class Contact extends Component {

  render() {

    const firm = require('../attorney-information/firmInfo.json')[0]

    return (
      <div className='attorneys-container'>
        <Helmet>
          <title>Contact</title>
          <meta name="description" content="Szenberg & Okun PLLC address, email and phone information." />
        </Helmet>
        {/* Header */}
        <div className='so-attorneys-grid-main-head'>Contact Szenberg & Okun PLLC</div>
        {/* Stripe */}
        <div className='so-attorneys-grid-stripe'></div>
        <div className='so-attorneys-grid-secondary-stripe'></div>
        {/* Attorneys */}
        <div className='so-attorneys-grid-attorneys'>
          {/* Address */}
          {firm.Address_Street}, {firm.Address_Street2}<br />
          {firm.Address_City}, {firm.Address_State}  {firm.Address_Zip}<br />
          {firm.Firm_Tel.toString().substring(0, 3)}.{firm.Firm_Tel.toString().substring(3, 6)}.{firm.Firm_Tel.toString().substring(6, 10)}<br />
          {firm.Firm_Fax.toString().substring(0, 3)}.{firm.Firm_Fax.toString().substring(3, 6)}.{firm.Firm_Fax.toString().substring(6, 10)}<br />
          {<div className='pointable'><a href={`mailto:${firm.Firm_Email}`}>{firm.Firm_Email}</a></div>}

          {/* Google Map */}
          <div>
            <div className="embed-responsive">
              <iframe title="Office Location" className="embed-responsive-item" frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src="https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=152+West+57th+Street,+New+York,+NY&aq=0&oq=152+west&sll=41.066377,-73.862887&sspn=0.134083,0.209255&t=m&ie=UTF8&hq=&hnear=152+W+57th+St,+New+York,+10019&z=14&iwloc=A&output=embed" />
            </div>
            {/* <br />
              <small><a href="https://maps.google.com/maps?f=q&source=embed&hl=en&geocode=&q=152+West+57th+Street,+New+York,+NY&aq=0&oq=152+west&sll=41.066377,-73.862887&sspn=0.134083,0.209255&t=m&ie=UTF8&hq=&hnear=152+W+57th+St,+New+York,+10019&z=14&iwloc=A" style={{ color: '#0000FF', textAlign: 'left' }}>View Larger Map</a></small><br /> */}
          </div>
        </div>


        <div className='so-attorneys-grid-main-overall'>    </div>
        {/* Secondary */}
        <div className='so-attorneys-grid-secondary-overall'>    </div>
        <div className='so-attorneys-grid-secondary-head'></div>
        <div className='so-attorneys-grid-secondary'><PicturePicker /></div>
        <div className='so-attorneys-grid-secondary-border'></div>
      </div >
    )
  }
}

export default withRouter(Contact)
