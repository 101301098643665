// import React from 'react'
// var vCardsJS = require('vcards-js')
// var base64Img = require('base64-img')

//Can't figure out base64 conversion on the fly, so I ended up having to:
//1. Convert the images manually here:  https://www.base64-image.de/
//2. Fix the conversions here https://base64.guru/tools/repair
//3. Save the string into the json file and then manually stick it in below

export default function VCFCreator(attorney) {

  const firm = require('../attorney-information/firmInfo.json')[0]

  return (
`
BEGIN:VCARD
VERSION:2.1
N;LANGUAGE=en-us:${attorney.ELName};${attorney.EFName}
FN:${attorney.EFName} ${attorney.ELName}
ORG:${firm.Firm_Name}
TITLE:${title(attorney.ELevel)}
TEL;WORK;VOICE:(${attorney.ETel.toString().substring(0, 3)}) ${attorney.ETel.toString().substring(3, 6)}-${attorney.ETel.toString().substring(6, 10)}
TEL;WORK;FAX:(518) 244-8188
ADR;WORK;PREF:;;${firm.Address_Street}, ${firm.Address_Street2};${firm.Address_City};${firm.Address_State};${firm.Address_Zip};United States of America
LABEL;WORK;PREF;ENCODING=QUOTED-PRINTABLE:${firm.Address_Street}, ${firm.Address_Street2}=0D=0A=
${firm.Address_City}, ${firm.Address_State}  ${firm.Address_Zip}
X-MS-OL-DEFAULT-POSTAL-ADDRESS:2
EMAIL;PREF;INTERNET:${attorney.EEMail}
PHOTO;TYPE=JPEG;ENCODING=BASE64:
 ${attorney.EImage64}

X-MS-OL-DESIGN;CHARSET=utf-8:<card xmlns="http://schemas.microsoft.com/office/outlook/12/electronicbusinesscards" ver="1.0" layout="left" bgcolor="ffffff"><img xmlns="" align="tleft" area="32" use="photo"/><fld xmlns="" prop="name" align="left" dir="ltr" style="b" color="000000" size="10"/><fld xmlns="" prop="org" align="left" dir="ltr" color="000000" size="8"/><fld xmlns="" prop="title" align="left" dir="ltr" color="000000" size="8"/><fld xmlns="" prop="blank" size="8"/><fld xmlns="" prop="telwork" align="left" dir="ltr" color="000000" size="8"><label align="right" color="626262">Work</label></fld><fld xmlns="" prop="email" align="left" dir="ltr" color="000000" size="8"/><fld xmlns="" prop="addrwork" align="left" dir="ltr" color="000000" size="8"/><fld xmlns="" prop="webwork" align="left" dir="ltr" color="000000" size="8"/><fld xmlns="" prop="blank" size="8"/><fld xmlns="" prop="blank" size="8"/><fld xmlns="" prop="blank" size="8"/><fld xmlns="" prop="blank" size="8"/><fld xmlns="" prop="blank" size="8"/><fld xmlns="" prop="blank" size="8"/><fld xmlns="" prop="blank" size="8"/><fld xmlns="" prop="blank" size="8"/></card>
REV:20190224T182800Z
END:VCARD



`
  )
}


// //This one doesn't seem to work with the address for some reason :(
// function VCFCreator2(attorney) {

//   const firm = require('../attorney-information/firmInfo.json')[0]

//   // var data = base64Img.base64Sync(`../images/${attorney.EImage}`)
//   // console.log(data)
//   var xhr = new XMLHttpRequest();       
//   xhr.open("GET", `../images/${attorney.EImage}`, true); 
//   xhr.responseType = "blob";
//   xhr.onload = function (e) {
//           console.log('This is this.response', this.response);
//           var reader = new FileReader();
//           reader.onload = function(event) {
//              var res = event.target.result;
//              console.log('this is res: ', res)
//           }
//           var file = this.response;
//           reader.readAsDataURL(file)
//           reader.onload = () => {
//           console.log('This is reader: ', reader.result)}
//   };
//   xhr.send()
//   // let fileInfo = {
//   //   name: 'file.name',
//   //   type: 'file.type',
//   //   size: Math.round(file.size / 1000) + ' kB',
//   //   base64: reader.result,
//   //   file: file,
//   // };

//   //create a new vCard
//   let vCard = vCardsJS();

//   //set properties
//   vCard.firstName = attorney.EFName
//   vCard.middleName = attorney.EMName
//   vCard.lastName = attorney.ELName
//   vCard.workEmail = attorney.EEMail
//   vCard.organization = firm.Firm_Name
//   // vCard.photo.embedFromFile(`../images/${attorney.EImage}`)
//   vCard.workPhone = `(${attorney.ETel.toString().substring(0, 3)}) ${attorney.ETel.toString().substring(3, 6)}-${attorney.ETel.toString().substring(6, 10)}`
//   vCard.workFax = `(518) 244-8188`
//   vCard.title = title(attorney.ELevel)
//   vCard.url = 'https://szenok.com'

//   vCard.workAddress.label = 'Work Address';
//   vCard.workAddress.street = firm.Address_Street;
//   // vCard.workAddress.street2 = firm.Address_Street2;
//   vCard.workAddress.city = firm.Address_City;
//   vCard.workAddress.stateProvince = firm.Address_State;
//   vCard.workAddress.postalCode = firm.Address_Zip;
//   vCard.workAddress.countryRegion = 'United States of America';

//   // console.log(vCard.getFormattedString())

//   return vCard
// }

const title = (level) => {
  switch (level) {
    case 0:
      return 'Founding Partner'
    case 1:
      return 'Partner'
    case 2:
      return 'Counsel'
    case 3:
      return 'Associate'
    default:
    // code block
  }
}