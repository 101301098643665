import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import StripedBox from '../components/StripedBox'
import './practices.css'
import { withRouter } from 'react-router-dom'

function Notice({ location }) {

  const [type, setType] = useState('pllc')
  // const [picker, setPicker] = useState('pllc')

  //Set the type
  useEffect(() => {
    const noticePathname = location.pathname.split('/')[2].split('-')[0]
    // console.log('type is: ', noticePathname)
    setType(noticePathname)
  }, [location.pathname])

  let picker = () => {
    // console.log('This is the type: ', type)
    switch (type) {
      case 'pllc':
        return { header: 'PLLC Notice', content: pLLCNotice() }
      case 'attorney':
        return { header: 'Attorney Advertising Notice', content: attorneyNotice() }
      case 'web':
        return { header: 'Web Site Notice', content: webNotice() }
      default:
        return { header: 'PLLC Notice', content: pLLCNotice() }
    }
  }

  return (
    <div className='notices-container'>
      <Helmet>
        <title>{picker().header}</title>
        <meta name="description" content={picker().header} />
      </Helmet>
      <div className='so-grid-main'>
        <StripedBox Heading={picker().header} Content={picker().content} />
      </div>
    </div>
  )
}

export default withRouter(Notice)


export const pLLCNotice = () => {
  return (
    <div>
      <p>Szenberg &amp; Okun PLLC has registered as a professional limited liability company under the laws of the State of New York. The personal liability of our members is limited to the extent provided in such laws.</p>
      <p>Specifically, under the laws of the State of New York, each member of a registered professional limited liability company is not personally liable for debts, obligations and liabilities of the firm, except that each member of a registered professional limited liability company remains personally and fully liable and accountable for any negligent or wrongful act or misconduct committed by him or her or by any person under his or her direct supervision and control while rendering professional services on behalf of the firm or as otherwise provided by the laws governing registered professional limited liability companies. </p>
      <p>Additional information is available upon request.</p>
    </div>
  )
}
export const attorneyNotice = () => {
  return (
    <div>
      <h4>Prior results do not guarantee a similar outcome.</h4>
      <p>This website and its contents are intended to be used solely for informational purposes.  Descriptions of success do not indicate a likelihood of success with respect to any other matter or an ability to   reach a successful conclusion with respect to any client issues.    Szenberg &amp; Okun PLLC cannot and does not guarantee the results of any matter we handle. Prior results do not guarantee a similar outcome.</p>
    </div>
  )
}
export const webNotice = () => {
  return (
    <div>
      <p>This website contains general information about our firm, Szenberg &amp; Okun PLLC, for clients, potential clients, and individuals considering possible employment with our Firm. This website is not intended to be a source of legal advice. Therefore, you should not consider this information to be an invitation for an attorney-client relationship, should not rely on the information provided herein as legal advice for any purpose, and should always seek the legal advice of competent counsel in your jurisdiction.</p>
      <p>This website contains links to other sites. S&amp;O does not necessarily endorse, and is not responsible for, any third-party content that may be accessed through this website. Also, please be aware that we are not and cannot be responsible for the privacy practices of other websites. We encourage you to read the privacy statements of all third-party websites that you visit. </p>
      <p>The Firm maintains offices in New York and does not intend or purport to practice in any other jurisdictions. <br />
        <br />
      We may monitor the use of this website and record the Internet IP address of users of this website. This information is collected by Szenberg &amp; Okun PLLC and its agents for our internal purposes only and shall not be disclosed to any other organization except as required by law. In addition, all e-mail communications with S&amp;O personnel or representatives through Firm-provided email accounts are subject to monitoring and review in accordance with Firm policy and applicable law. </p>
      <p>Szenberg &amp; Okun PLLC is a professional limited liability company registered under the laws of the State of New York.<br />
        <br />
      ©2020 Szenberg &amp; Okun PLLC. All rights reserved. The reproduction or retransmission of the contents of this website is prohibited without the prior written consent of Szenberg &amp; Okun PLLC. </p>
    </div>
  )
}
